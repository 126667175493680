<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <!-- <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">Create an Account</li>
        </ol> -->
      </div>
    </nav>

    <div class="page-content py-5">
      <div class="container">
        <div class="row justify-content-center main-create">
          <div class="col-md-10">
            <div class="bg-white br-1 p-4 mx-1">
              <h5 class="text-primary text-left text-md-center pt-1 pt-md-3"
                style="font-size: 2.4rem; font-weight: 600">
                {{ $t("create_a_new_account") }}
              </h5>
              <hr class="d-none d-md-flex mt-2" />
              <form action="#" @submit.prevent="formSubmit">
                <div class="row mb-1">
                  <div class="col-md-6">
                    <h6 class="text-muted">
                      {{ $t("personal_information") }}
                    </h6>
                  </div>
                  <div class="col-md-6 d-none d-md-block">
                    <h6 class="text-muted">
                      {{ $t("login_information") }}
                    </h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="Naam" class="text-dark">{{ $t("form_first_name") }} *</label>
                      <b-form-input v-validParent type="text" class="form-control" id="Naam" name="Naam"
                        :placeholder="$t('form_first_name')" v-model="form.firstname" :state="form.firstnameState"
                        @input="validateFirstname"></b-form-input>
                      <b-form-invalid-feedback id="firstname-feedback">
                        {{ form.firstnameError }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="form-group">
                      <label for="last_name" class="text-dark">{{
                        $t("last_name")
                      }}</label>
                      <b-form-input v-validParent type="text" class="form-control" id="last_name" name="last_name"
                        :placeholder="$t('last_name')" v-model="form.lastname" :state="form.lastnameState"
                        @input="validateLastname"></b-form-input>
                      <b-form-invalid-feedback id="lastname-feedback">
                        {{ form.lastnameError }}
                      </b-form-invalid-feedback>
                      <div class="custom-control custom-checkbox pl-0">
                        <input v-model="form.isCompany" type="checkbox" class="custom-control-input" id="is-company" />
                        <label class="custom-control-label pl-5 text-dark" for="is-company">{{
                          $t("register_form_company_question") }}</label>
                      </div>
                    </div>

                    <div v-if="this.getCompany">
                      <div class="form-group">
                        <label class="text-dark" for="company_name">{{
                          $t("company_name")
                        }}</label>
                        <b-form-input v-validParent type="text" class="form-control" id="company_name"
                          name="company_name" :placeholder="$t('company_name')" v-model="form.company"
                          :state="form.companyState" @input="validateCompany"></b-form-input>
                        <b-form-invalid-feedback id="company-feedback">
                          {{ form.companyError }}
                        </b-form-invalid-feedback>
                      </div>
                      <div class="form-group">
                        <label for="kvknumber" class="text-dark">{{ $t("form_coc_number") }}</label>
                        <input type="text" class="form-control" id="kvknumber" name="kvknumber" />
                      </div>
                      <div class="form-group">
                        <label for="btwnumber" class="text-dark">{{ $t("form_vat_number") }}</label>
                        <b-form-input v-validParent type="text" class="form-control" id="btwnumber" name="btwnumber" 
                          v-model="form.vatNumber" :state="form.vatNumberState" @input="validateVatNumberField"></b-form-input>
                        <b-form-invalid-feedback id="vat-feedback">
                          {{ form.vatNumberError }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 d-block d-md-none my-3 mt-2 mt-md-0">
                    <h6>
                      <strong>
                        {{ $t("login_information") }}
                      </strong>
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="email" class="text-dark">{{ $t("email_address") }} *</label>
                      <b-form-input v-validParent type="email" class="form-control" id="email" name="email"
                        :placeholder="$t('email')" v-model="form.email" :state="form.emailState"
                        @input="validateEmail"></b-form-input>
                      <b-form-invalid-feedback id="email-feedback">
                        {{ form.emailError }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="form-group">
                      <label for="password" class="text-dark">{{
                        $t("password")
                      }}</label>
                      <b-form-input v-validParent type="password" class="form-control" id="password" name="password"
                        v-model="form.password" :state="form.passwordState" @input="validatePassword"></b-form-input>
                      <b-form-invalid-feedback id="password-feedback">
                        {{ form.passwordError }}
                      </b-form-invalid-feedback>
                      <p class="text-dark my-1 my-md-0 create-p">
                        Wachtwoordsterkte: Geen wachtwoord
                      </p>
                    </div>
                    <div class="form-group">
                      <label for="confirm_password" class="text-dark">{{
                        $t("confirm_password")
                      }}</label>
                      <b-form-input v-validParent type="password" class="form-control" id="confirm_password"
                        name="confirm_password" v-model="form.password_confirm" :state="form.password_confirmState"
                        @input="validateConfirmationPassword"></b-form-input>
                      <b-form-invalid-feedback id="confirm-password-feedback">
                        {{ form.password_confirmError }}
                      </b-form-invalid-feedback>
                      <div class="custom-control custom-checkbox pl-0">
                        <input type="checkbox" class="custom-control-input" v-model="form.is_subscribed"
                          id="newsletter" />
                        <label class="custom-control-label pl-5 text-dark" for="newsletter">{{
                          $t("register_for_our_newsletter") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-0 mt-md-2">
                  <div class="col-md-12">
                    <div class="form-footer justify-content-end justify-content-md-center">
                      <button type="submit" class="btn btn-success btn-lg font-weight-light mt-1 mt-md-0">
                        <span>{{ $t("create_account") }}</span>
                        <!-- <i class="icon-long-arrow-right"></i> -->
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 d-flex justify-content-end justify-content-md-start mt-2 mt-md-0">
                    <router-link to="/">
                      <font-awesome-icon class="text-primary" icon="fa-solid fa-angle-left" />
                      {{ $t("continue_shopping") }}</router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { checkEmailExist, validateVatNumber } from "@storefront/core/data-resolver/user";
import config from "@config";

export default {
  data() {
    return {
      useremail: "",
      thanksActive: false,
      showFullAddress: true,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      isInvoiceCompany: false,
      isValidInvoiceVatNumber: false,
      showFullInvoiceAddress: false,
      invoiceAddressTimer: null,
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      selectedInvoiceAddress: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regExNlPhone:
        /^((\+|00)31[1-9][0-9]{8}||0[1-9][0-9][- ]?[1-9][0-9]{6}||0[1-9][0-9]{2}[- ][1-9][0-9]{5}||06[- ]?[1-9][0-9]{7})$/,
      regExBePhone:
        /^(((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}||((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3})$/,
      regExDePhone:
        /^((\+|00)49[1-9][0-9]{9}||0[1-9][0-9]{9}||015[1-9][0-9]{8}||01(6|7)[1-9][0-9]{7,8}||(\+|00)491(6|7)[1-9][0-9]{7,8})$/,
      regExGenPhone: /^(\+|00)[1-9][0-9]+$/,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        isCompany: false,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        invoice: {
          address: {
            country_code: "",
          },
        },
        address: {
          country_code: "",
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
        vatNumber: "",
        vatNumberState: null,
        vatNumberError: "",
        vatNumberTimer: null,
      },
    };
  },
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
  },
  created() {
    this.form.email = this.$route.params.useremail;
  },
  computed: {
    getCompany() {
      return this.form.isCompany;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  methods: {
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    async validateEmail() {
      // Clear any existing timer
      clearTimeout(this.form.emailTimer);

      // Basic validations that don't require API calls
      if (!this.form.email || this.form.email.length === 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }

      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }

      // Set to pending state while checking
      this.form.emailState = null;

      // For API call, use debouncing to avoid too many calls
      return new Promise(resolve => {
        this.form.emailTimer = setTimeout(async () => {
          try {
            const emailExists = await checkEmailExist(this.form.email);
            
            if (emailExists === false) {
              this.form.emailState = false;
              this.form.emailError = this.$t("email_already_exists");
              resolve(false);
            } else {
              this.form.emailState = true;
              resolve(true);
            }
          } catch (error) {
            console.error("Error checking email:", error);
            // If there's an error checking, assume it's available rather than blocking registration
            this.form.emailState = true;
            resolve(true);
          }
        }, 500); // Wait 500ms after typing stops
      });
    },
    validatePassword() {
      if (this.form.password.length < 8) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[a-z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (
        this.form.password.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/) == null
      ) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/[A-Z]/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else if (this.form.password.match(/\d/) == null) {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_needs");
      } else {
        this.form.passwordState = true;
      }
    },
    validateConfirmationPassword() {
      if (
        this.form.password_confirm &&
        this.form.password != this.form.password_confirm
      ) {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_not_equal");
      } else if (this.form.password_confirm == "") {
        this.form.password_confirmState = false;
        this.form.password_confirmError = this.$t("password_confirm_required");
      } else {
        this.form.password_confirmState = true;
      }
    },
    validateCompany() {
      if (this.form.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    async validateVatNumberField() {
      clearTimeout(this.form.vatNumberTimer);
      
      if (!this.form.vatNumber || this.form.vatNumber.trim() === "") {
        this.form.vatNumberState = null;
        return true;
      }
      
      this.form.vatNumberState = null;
      
      return new Promise(resolve => {
        this.form.vatNumberTimer = setTimeout(async () => {
          try {
            const isValid = await validateVatNumber(this.form.vatNumber);
            
            if (isValid) {
              this.form.vatNumberState = true;
              resolve(true);
            } else {
              this.form.vatNumberState = false;
              this.form.vatNumberError = this.$t("invalid_vat_number");
              resolve(false);
            }
          } catch (error) {
            console.error("Error validating VAT number:", error);
            this.form.vatNumberState = false;
            this.form.vatNumberError = this.$t("error_validating_vat");
            resolve(false);
          }
        }, 500); 
      });
    },
    async formSubmit() {
      let formValid = true;
      
      const emailValid = await this.validateEmail();
      if (!emailValid) {
        formValid = false;
      }
      
      if (this.form.passwordState != true) {
        this.validatePassword();
        if (this.form.passwordState != true) {
          formValid = false;
        }
      }
      if (this.form.password_confirmState != true) {
        this.validateConfirmationPassword();
        if (this.form.password_confirmState != true) {
          formValid = false;
        }
      }

      if (this.form.isCompany == true) {
        if (this.form.companyState != true) {
          this.validateCompany();
          if (this.form.companyState != true) {
            formValid = false;
          }
        }
        if (this.form.vatNumber) {
          const vatValid = await this.validateVatNumberField();
          if (!vatValid) {
            formValid = false;
          }
        }
      }
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.lastnameState != true) {
          formValid = false;
        }
      }
      // if (this.form.address.postcodeState != true) {
      //     this.validatePostcode();
      //     if (this.form.address.postcodeState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.address.house_numberState != true) {
      //     this.validateHouseNumber();
      //     if (this.form.address.house_numberState != true) {
      //         formValid = false;
      //     }
      // }
      // if (this.form.address.streetDisplayState != true) {
      //     this.validateStreet();
      //     if (this.form.address.streetDisplayState != true) {
      //         formValid = false;
      //         this.showFullAddress = true;
      //     }
      // }
      // if (this.form.address.telephoneState != true) {
      //     this.validatePhone();
      //     if (this.form.address.telephoneState != true) {
      //         formValid = false;
      //     }
      // }

      // if (this.form.address.cityState != true) {
      //     this.validateCity();
      //     if (this.form.address.cityState != true) {
      //         formValid = false;
      //     }
      // }
      console.log("formValid", formValid);
      if (formValid == true) {
        // Create a copy of the form data to modify before submission
        const accountData = JSON.parse(JSON.stringify(this.form));
        
        // Get the form values for KVK and BTW
        const kvkNumber = document.getElementById('kvknumber')?.value || '';
        const btwNumber = this.form.vatNumber || '';
        
        // Prepare address information if it's a company
        if (this.form.isCompany) {
          // Ensure address and invoice.address objects exist
          if (!accountData.address) accountData.address = {};
          if (!accountData.invoice) accountData.invoice = {};
          if (!accountData.invoice.address) accountData.invoice.address = {};
          
          // Add company information to address fields instead
          accountData.address.company_name = this.form.company;
          accountData.address.vat_id = btwNumber;
          accountData.address.chamber_of_commerce = kvkNumber;
          
          // Also add to invoice address if present
          accountData.invoice.address.company_name = this.form.company;
          accountData.invoice.address.vat_id = btwNumber;
          accountData.invoice.address.chamber_of_commerce = kvkNumber;
        }
        
        // Remove all company-related fields that aren't in CustomerCreateInput
        delete accountData.company;
        delete accountData.companyState;
        delete accountData.companyError;
        delete accountData.companyTimer;
        delete accountData.isCompany;
        
        // Remove other state/validation fields that might cause issues
        const fieldsToRemove = [
          'emailState', 'emailError', 'emailTimer',
          'passwordState', 'passwordError', 'passwordTimer',
          'password_confirmState', 'password_confirmError', 'password_confirmTimer',
          'firstnameState', 'firstnameError', 'firstnameTimer',
          'lastnameState', 'lastnameError', 'lastnameTimer'
        ];
        
        fieldsToRemove.forEach(field => {
          delete accountData[field];
        });
        
        console.log('Submitting account data:', accountData);
        
        const retval = await this.$store.dispatch("user2/createAccountV2", {
          account: accountData,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: "account_create",
            text: this.$t("account_created"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/login");
        }
      }
      //this.thanksActive = true;
    },
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("create_new_account") },
        { name: "keywords", content: this.$t("create_new_account") },
        { name: "description", content: this.$t("create_new_account") },
      ],
      title: this.$t("create_new_account"),
    };
  },
};
</script>
